.header {
    font-family: 'Inter', sans-serif;
    z-index: 5;
}

.menuBtn {
    color: $font-secondary;
}

.header .title {
    font-size: 1.3em;
    margin-left: 1em;
}

.header .dev-alert {
    background-color: $background-red;
    padding: 0px 10%;
    height: 70px;
    color: $font-white;
    font-size: 1em;
    font-weight: bold;
    margin: auto;
}

.header .prod-alert {
    background-color: $background-red;
    padding: 0px 10%;
    height: 70px;
    color: $font-white;
    font-size: 1.5em;
    font-weight: bold;
    margin: auto;
}

.dev-alert div {
    margin: auto;
}

.prod-alert div {
    margin: auto;
}

.header .assistance-bar {
    background-color: $background-dark;
    font-size: 0.85em;
    padding: 3px 10%;
    color: $font-light;
}

.header a {
    color: #4F5858;
}

.header .color-white {
    color: $font-white;
}

.assistance-bar a {
    text-decoration: none;
    display: inline;
}

.assistance-bar .separator {
    font-size: 1.8em;
    font-weight: lighter;
    padding: 0px 5px;
    color: $font-white;
}

.assistance-bar .tar-logo {
    height: 1.3em;
    width: auto;
    vertical-align: middle;
}

.header .language-selector {
    color: #4F5858;
    font-size: 1em;
}

.language-selector .country-flag {
    height: 10px;
    padding: 0px 4px;
}

.language-selector .menu-item {
    background: #4F5858;
}

.header .menu-bar {
    background-color: $background;
    height: 90px;
    padding: 0 130px;
    position: relative;
    z-index: 2;
}

.menu-bar .logo-container {
    margin-right: auto;
}

.logo-container .logo {
    height: 60px;
    width: 133px;
    margin-left: 10px;
}

.menu-bar .peak {
    width: 170px;
    position: absolute;
    top: 90px;
    left: calc(10% - 40px);
    z-index: 10;
}

.menu-bar a {
    font-weight: 600;
    text-decoration: none;
    margin-right: 15px;
    cursor: pointer;
}

.menu-bar a:hover {
    color: $font-secondary;
}

.menu-bar .star-pass {
    background-color: $background-red;
    background: linear-gradient(180deg, $background-red, $background-red-dark);
    border-radius: 5px;
    cursor: pointer;
}

.star-pass a {
    padding: 10px 40px;
    display: block;
    margin: 0;
}

.star-pass img {
    width: 100px;
    height: 17px;
}

.selectorLan {
    align-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 0.85em;
    margin: 0;
    padding: 0;
}

.selectorLang {
    cursor: pointer;
    display: flex;
}

.flag-Lang {
    font-size: 0.85em;
    height: 1em;
    width: 2em;
    margin: 0 !important;
    padding: 0 !important;
}

.btnLang {
    color: #4F5858;
    font-weight: 600;
    font-size: 0.85em;
    margin-left: 5px;
    padding: 0;


    svg {
        display: none;
    }

}

.btnSelect {
    display: flex;
    color: #4F5858;
    font-weight: bold;

    p {
        margin: 0 4px;
    }
}

@media only screen and (max-width: 899px) {
    .header .dev-alert {
        padding: 0px 2%;
    }

    .header .prod-alert {
        padding: 0px 2%;
    }

    .header .assistance-bar {
        padding: 0px 2%;
    }

    .header .menu-bar {
        padding: 0px 2%;
    }

    .menu-bar .logo-container {
        left: 2%;
    }

    .menu-bar .peak {
        left: calc(2% - 40px);
    }
}

@media only screen and (max-width: 460px) {
    .movile_lang {
        margin-left: auto !important;
        width: 30%;
    }

    .btnSelect {
        color: $font-black;
        font-size: 1.3em;
    }

    .btnLang {
        color: $font-black;
        font-size: 1.3em;
    }
}

.header .menu-bar-mobile {
    background-color: $background;
    box-shadow: 0px 0px 3px 0px $background-dark;
    padding: 0px 10px;
}

.menu-bar-mobile .logo {
    height: 35px;
    width: auto;
    margin-left: 10px;
}

.menu-bar-mobile .menu-icon {
    color: $font-secondary;
    font-size: 50px;
}

.mobileShop {
    padding-left: 92%;
    color: #e51f04;
    padding-top: 2%;
}

.mobileShop svg {
    font-size: 1.2rem;

}

@media only screen and (max-width: 650px) {
    .header {
        font-size: 0.9em;
    }

    .btnSelect {
        color: $font-black;
        font-size: 1.3em;
    }

    .btnLang {
        color: $font-black;
        font-size: 1.3em;
    }
}