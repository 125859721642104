.calendar {
    font-family: 'Montserrat', sans-serif;
}

.cntCalendardays {
    display: inline-block !important;

    p {
        color: #7F7F7F;
        font-weight: 600;
        font-size: 0.8em;
    }
}

.titleCalendar {
    font-weight: 400;
    display: inline-flex;
    align-items: center;

    img {
        width: 1.2em;
        height: 1.2em;
    }

    span {
        margin: 0 7px;
        font-weight: 600;
    }
}

.tab-title-align {
    background-color: green;
    // margin-right: 60px;
}

.tab-title {
    font-size: 1.25em;
    color: $font-primary;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-transform: none;
}

.tab-subtitle {
    font-size: 1.5em;
    color: $font-primary;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    text-transform: none;
}

.red-plane {
    color: $red-plane;
    font-size: 22px;
}

.red-circle {
    background-color: $red-circle;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

.white-arrow {
    color: $font-white;
    height: 0.85em;
    width: 0.85em;
    margin-top: 0.325em;

}

.red-arrow {
    color: $font-secondary;
    height: 0.85em;
    font-size: 20px;
}

.red-text-medium {
    color: $font-secondary;
}

.gray-text {
    color: $font-light;
}

.disabled-week {
    cursor: default;
}

.selector-week {
    cursor: pointer;
}

.week-title {
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 1.1em;
    text-align: center;
}

.week-day {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 0.85em;
    text-align: center;
    color: $font-primary;
}

.month-day {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 0.8em;
    text-align: center;
    text-transform: capitalize;
    color: $font-black;
    line-height: 0.8em;
    margin: 1.3em 0 1.3em 0;
}

.selected-month-day {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 0.8em;
    text-align: center;
    text-transform: capitalize;
    color: $font-white;
    line-height: 0.8em;
    margin: 1.3em 0 1.3em 0;
}

.past-day {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 0.8em;
    text-align: center;
    text-transform: capitalize;
    color: $font-primary;
    line-height: 0.8em;
    margin: 1.3em 0 1.3em 0;
}

.selected-day {
    background-color: $background-red;
    cursor: pointer;
    color: $font-white;
    text-align: center;
    height: 7em !important;

    border-color: $border-gray;
    border-style: solid;
    border-width: 2px 1px;
}

.day {
    text-align: center;
    color: $font-secondary;
    cursor: pointer;
    background-color: $background;
    border-color: $border-gray;
    border-style: solid;
    border-width: 2px 1px;
    height: 7em !important;
}

.unable-day {
    text-align: center;
    color: $font-secondary;
    background-color: $background;
    border-color: $border-gray;
    border-style: solid;
    border-width: 2px 1px;
    height: 7em !important;
}

.amount {
    margin: 0.5em 0px 0.5em 0px !important;
    line-height: 0.8em;
}

.gray-circle {
    background-color: $gray-circle;
    border-radius: 50%;
    // cursor: default;
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    pointer-events: none;
}

.red-clock {
    color: $red-plane;
}

////FLIGHT////

.dayFligth {
    text-transform: capitalize;
    margin-top: 2em;
}

.cnt-Flight {
    display: inline-block;
}

.cardFlight {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.selected-false {
    display: none !important;
}

.btnCabins {
    display: grid !important;
    align-items: center;
    padding: 0px;
    align-content: center;
    justify-content: center;
    justify-items: center;

    button {
        width: 85%;
    }
}

.cntFlights {
    display: inline-flex;
    width: 100%;
}

.lblFlightNum {
    position: absolute;
    display: inline-flex;

    .lblFlights {
        color: $font-middle;
        font-size: 1em;
        font-weight: 600;
        margin-left: 1.2em;
    }
}

.hidden {
    display: none !important;
}

.btnContinue {
    width: 100%;
    float: right;
    padding: 0.5em 1em 0.5em 1em;
}

.calendar-Hrs {
    display: inline-flex;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: content-box;

    .DotsCalendar {
        display: inline-flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: content-box;
        margin: 11px;

        .DotDep,
        .DotRet {
            font-size: 0.5em;
        }

        .cntlineDot {
            width: 22vmax;
            display: inline-grid;
            text-align: center;
            align-content: center;
            align-items: center;

            .time {
                p {
                    margin-top: 2px;
                    font-size: 1em;
                    font-weight: 400;
                }
            }

            .lineDotsNon {
                border-top: 1px solid black;
                position: relative;
                top: 7px;
            }

            .lineDots {
                border-top: 1px solid black;
                position: relative;
                top: 17px;
            }

            .stops {

                .stopsDosts {
                    img {
                        height: 1em;
                        position: relative;
                        top: 0.5vw;
                    }
                }

                p {
                    margin-top: 36px;
                }
            }
        }
    }
}

.lowPriceCnt {
    display: inline-flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-left: 1px outset;
    padding-left: 2em;

    .lowPrice {
        cursor: pointer;
        display: inline-flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .noclick {
        pointer-events: none;
        cursor: none;
        display: inline-flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .prices-letters {

        label {
            font-size: 0.6em;
            font-weight: 600;
        }

        .price-flight {
            font-weight: 400;

            .util-currency-formatter {
                font-weight: 400;
                font-size: 1.5em;
            }

            .currency-currency {
                font-weight: 400;
                font-size: 0.7em !important;
                color: #9F9F9F;
            }
        }

        .more-flight {
            cursor: pointer;
            font-size: 1em;
            color: #CA301A;
            font-weight: 500;
        }
    }

    .more-flight {
        cursor: pointer;
        font-size: 1em;
        color: #CA301A;
        font-weight: 500;
        margin-top: 0.5em;
    }

    .svgBtn {
        cursor: pointer;

        img {
            height: 2vw;
            margin-left: 5vw;
        }
    }

    .closebtn {
        width: 90%;
        cursor: pointer;
        color: #E51F04;
        font-weight: 500;
        font-size: 1.4em;
        line-height: 19.5px;
        display: inline-flex;
        align-items: center;
        font-family: 'Montserrat', sans-serif;

        span {
            padding: 1em;
        }

        img {
            height: 1vw;
            margin-left: 7vw;
            margin-top: 1vw;
        }
    }

}

.flight-title {
    color: $font-secondary;
    // float: left;
    font-family: 'Montserrat', sans-serif;
    font-weight: 385;
    font-size: 1.5em;
}

.flight-detail-info {
    font-weight: 800;
    font-size: 1em;
    margin-top: -14px;
}

.flight-title-new {
    // float: left;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 2em;
}

.flight-cabin-selected {
    color: #ffffff;
    background-color: #e51f04;
    cursor: pointer;
}

.flight-cabin-selected-letter {
    color: #ffffff !important;
    font-weight: bold !important;
}


.flight-detail-title {
    font-weight: 500;
    font-size: 16px;
}

.flight-detail-ItinInformation {
    opacity: 0.3;
    font-weight: 400;
    font-style: italic;
}

.center-text {
    text-align: center;
}

.cabins {
    padding-top: 1em !important;
    padding-bottom: 2em !important;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
    justify-items: center;


}

.left {
    padding-left: 1em !important;
    padding-right: 0.5em !important;
}

.center {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
}

.right {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
}



.cabinFare {
    height: 40em;
    justify-items: center;
}

.selectedCabin {
    border: 1px solid #CA301A !important;
}

.flight-cabin {
    padding: 0 !important;
    margin: 2em;
    display: inline-grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: start;

    p {
        margin-block: 0 !important;
        unicode-bidi: unset;
    }

    .flight-cabin-letter {
        font-weight: 600;
        font-size: 1em;
    }

    .flight-cabin-price {
        font-size: 2.4em;

        .currency-currency {
            font-weight: 400;
            font-size: 0.7em !important;
            color: #9F9F9F;
        }


    }
}

.benefits {
    display: inline-table;
    justify-items: center;
    margin: 1em;
    font-size: 0.7em;
    line-height: 3em;
    color: #444444;

    svg {
        margin: 4px;
    }
}


.cabin-selected {
    background-color: $background-final-price;
    cursor: pointer;

    & div {
        color: $font-white;
        font-size: 16px;
        font-weight: bold;
    }
}

.empty-cabin {
    cursor: default;
}

.cabin {
    background-color: $font-white;
    cursor: pointer;
}


.conteinerWeek {
    display: flex;
    background-color: $background;
    width: 100%;
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #D8D8D8;
}

.selection {
    width: 98%;
    height: 0.5em;
    background-color: $background-gray-dark;
}

.selectionred {
    background-color: $red-circle;
}

.arrows {
    max-width: 3% !important;
    align-items: center;
    display: flex;
}

.prices {
    display: grid !important;
    text-align: center;
    padding-top: 1em;
    margin: 0 !important;
}

.snCalendar {
    color: #B4B4B4;
}

.prices h3 {
    font-weight: 400;
    margin: 0;
    padding-bottom: 1em;
}

.prices small {
    font-size: 0.6em;
}

.priceSelect {
    background-color: $background-red-light;
}

.prices h5,
.priceSelect h5 {
    color: #000000;
}

.prices h5,
.priceSelect h5 {
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 5px;
    font-weight: 600;
}

.SaveCalendar,
.returnCalendar {
    height: 3em;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    font-size: 0.9em !important;
    font-weight: 700 !important;
    line-height: 21.94px !important;
}

.SaveCalendar {
    float: inline-end;
}

.calendar-modal{
    font-family: "Montserrat";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .MuiBackdrop-root {
        background-color: rgba($color: $background, $alpha: 3/4%);
        justify-content: center;
        backdrop-filter: blur(8px);
    }

}

#calendar-selection-modal {
    position: relative;
    background-color: #fff;
    border-radius: 2em;
    width: 30%;
    min-height: 250px;
    padding: 3em;
    box-shadow: 0px 0px 13.3px 0px #0000001F;

    .modal-title {
        font-weight: 700;
        font-size: 1.7em;
        line-height: 26.82px;
    }

    .modal-description {
        font-weight: 400;
        font-size: 1em;
        line-height: 23.52px;
        padding: 1em 0;
        border-bottom: 1px solid #DFDFDF;

        strong {
            color: #E51F04;
        }

        span{
            line-height: 3.5em;
        }
    }

    .modal-buttons {
        display: inline-flex;
        padding: 1em 0;

        .btnCalendar{
            border-radius: 7px;
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            gap: 10px;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 0.9em;
        }

        #cancel-btn{
            margin-left: 2em;
        }
    }
}

@media only screen and (max-width:1400px){
    .flight-cabin .flight-cabin-price {
        font-size: 1.8em;
    }

    .calendar-Hrs {
        margin: 1em 0;

        .DotsCalendar {
            .cntlineDot {
                width: 9em;

                .stops {
                    .stopsDosts img {
                        top: 0.6vw;
                    }
                }


            }
        }
    }

    .lowPriceCnt {
        padding-left: 1em;

        .lowPrice, .noclick {
            justify-content: flex-start;
        }

        .closebtn img {
            margin-left: 4vw;
        }

        .svgBtn img {
            margin-left: 3vw;
        }

        .price-flight {
            .util-currency-formatter {
                font-size: 1.3em !important;
            }
        }

        
    }
}


@media only screen and (max-width: 1024px) {
    .prices h3 {
        font-size: 1em;
    }

    .prices h5 {
        font-size: 0.5em;
    }

    .prices small,
    .currency-currency {
        font-size: 0.5em;
    }

    .selected-true {
        display: inline-grid !important;
        justify-content: center;
        justify-items: center;
        align-items: center;
        max-width: 100%;
    }

    .cabins {
        max-width: 100% !important;
    }

    .calendar-Hrs {
        margin: 1em 0;

        .DotsCalendar {
            .cntlineDot {
                width: 7em;

                .lineDots {
                    top: 15px;
                }

                .stops {
                    .stopsDosts img {
                        top: 0.8vw;
                    }
                }


            }
        }
    }

    .flight-title-new {
        font-size: 1.6em;
    }

    .lowPriceCnt {
        padding-left: 1em;

        .lowPrice, .noclick {
            justify-content: flex-start;
        }

        .svgBtn img {
            height: 6vw;
            margin-left: 2vw;
        }

        .price-flight {
            .util-currency-formatter {
                font-size: 1.3em !important;
            }
        }

        
    }


}

@media only screen and (min-width:320px) and (max-width: 460px) {
    .app {
        font-size: 1.5vh;
    }

    .gray-circle,
    .red-circle {
        font-size: 10px
    }

    .prices h3 {
        font-size: 0.7em;
    }

    .prices h5 {
        font-size: 0.5em;
    }

    .prices small,
    .currency-currency {
        font-size: 0.5em;
    }

    .selected-true {
        display: inline-grid !important;
        justify-content: center;
        justify-items: center;
        align-items: center;
        max-width: 100%;
    }

    .cabins {
        max-width: 100% !important;
    }

    .calendar-Hrs {
        margin: 1em 0;

        .DotsCalendar {
            .cntlineDot {
                width: 10em;

                .lineDots {
                    top: 11px;
                }

                .stops {
                    .stopsDosts img {
                        top: 1.5vw;
                    }
                }

                .lineDotsNon {
                    top: 4px;
                }

            }
        }
    }

    .lowPriceCnt {
        .svgBtn img {
            height: 10vw;
            margin-left: 2vw;
        }

        .closebtn img {
            height: 4vw;
        }

        .lowPrice {
            justify-items: center;

        }
    }

    #calendar-selection-modal {
        width: 90%;
    }

    .dayFligth {
        margin-left: 3vw;
    }



}
