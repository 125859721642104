.react-swipeable-view-container {
    height: 100%;
}

.swipesCon {
    width: 100%;
    height: 100%;
}

.swipesCon a {
    width: 100%;
    height: 100%;
}

.swipesCon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swipe {
    position: absolute;
}

.swipes {
    background-color: transparent;
    overflow: hidden !important;
}

.stepperPromos {
    background-color: transparent !important;
    color: white !important;

    .MuiMobileStepper-dot {
        width: 0.8em;
        height: 0.8em;
    }

    .MuiMobileStepper-dotActive {
        background-color: $background;
    }
}

.BtnRigth svg,
.BtnLeft svg {
    font-size: 7em;
    color: $font-white;
}

.BtnRigth:disabled,
.BtnLeft:disabled {
    svg {
        color: $font-middle;
        opacity: 0.6;
    }
}

.titleSec {
    font-size: 2em;
    border-bottom: $border-gray 1px solid;
    margin-bottom: 1em;
}

.itemSec {
    padding: 0 1em 2em;
    align-items: center;
    text-align: center;
}

.paperSec {
    width: 10em;
    height: 10em;
    padding: 1em;
}

.ImgSec {
    height: 60%;
}

.itemSec img {
    width: 100%;
    height: auto;
    margin: 2.5em 0;
}

.lblSec {
    height: 40%;
}

@media only screen and (max-width: 899px) {
    .promotions .items {
        padding: 0 5px;
    }

    .items .promotion {
        height: 180px;
        padding: 10px;
    }

    .BtnRigth svg,
    .BtnLeft svg {
        font-size: 4em;
    }
}

@media only screen and (max-width: 599px) {

    .items .promotion {
        height: 130px;
        padding: 10px;
    }

    .carousel img {
        height: 100%;
        min-height: unset;
        width: 100%;
    }

    .BtnRigth svg,
    .BtnLeft svg {
        font-size: 4em;
    }
}