.promotions-page {
    padding-bottom: 2em;
    font-size: 1em;
    margin: auto;

    h1 p {
        margin: 0;
        margin-bottom: 2em;
        text-align: justify;
    }

    .block-img {
        text-align: center;
    }

    img {
        max-width: 100%;
        height: auto;
        width: -webkit-fill-available;
    }

    table {
        width: 100%;

    }

    .Promimages, .promoimg {
        display: contents;
    }

    .promoInf {
        display: grid;
        justify-items: center;
        padding: 2px;
    }

    .btnProm {
        width: -webkit-fill-available;
    }


}

.promo-selection-modal {
    position: absolute;
    background-color: #fff;
    top: calc(50% - 220px);
    left: 0;
    width: 100%;
    min-height: 250px;
    padding: 1.5em 20%;

    .alertPromo {
        color: $font-primary;
        text-align: justify;
    }
}


@media only screen and (max-width: 899px) {
    .promotions-page {
        padding: 0 1%;
        font-size: 0.9em;
    }

    .promo-selection-modal {
        padding: 1.5em 10%;

        .alertPromo {
            overflow: scroll;
            height: 350px;
        }
    }
}

@media only screen and (max-width: 320px) {

    .promoInf {
        width: 50%;
    }

    .promo-selection-modal {
        padding: 1.5em 10%;

        .alertPromo {
            overflow: scroll;
            height: 350px;
        }
    }

    .btnProm {
        font-weight: 600 !important;
        font-size: 0.700rem !important;
        line-height: 1 !important;
    }



}