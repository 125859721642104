.reset{
    margin: 2vw;
    padding: 2vw;
    text-align: center;
    font-size: 3vh;
}

input {
    font-size: 0.85em;
    margin: 10px 30px 10px 0;
    color: $font-middle;
    padding: 5px 20px;
    font-weight: 300;
}