.passengers {
    .title {
        font-weight: 400;
        font-size: 2.1em;
        margin: 0.5em 0;
    }

    .content {
        margin-bottom: 2em;
    }

    .passenger {
        .panel-title {
            text-transform: uppercase;
            border-top: 1px solid $border-light;
            color: $font-light;

            .passenger-name {
                font-variant: small-caps;
                text-transform: capitalize;
                font-size: 2em;
            }
        }

        .render-input-birthday>.MuiInputBase-formControl {
            flex-direction: row-reverse;

            .MuiIconButton-root {
                padding: 0;
                margin-right: auto;
            }

            .MuiInputAdornment-root {
                margin-left: 10px;
            }

            svg {
                width: 1.1em;
                height: 1.1em;
            }
        }

        .optional {
            font-size: 0.5em;
            color: $background-red-dark;
        }

        input {
            margin: 10px 30px 10px 0;
            color: $font-primary;
            padding: 0;
            font-weight: 300;
        }

        .phoneNumbers {
            display: inline-flex;
            width: 100%;
        }

        .countryCode {
            color: $font-middle;
            font-weight: 300;
            width: 4.5em;

            .special-label {
                display: none !important;
            }

            input {
                margin: 0;
                width: 100%;
                height: 2.3em;
                border: 0;
                padding: 0;
                pointer-events: none;
                text-align: right;
            }
        }

        .phoneNum {
            width: 100%;
        }

        .flag-dropdown {
            .selected-flag {
                width: 0;
                padding: 0;
            }
        }

        .MuiInput-root {
            input {
                margin: unset;
                padding: unset;
                font-size: 1.2em;
            }
        }

        .MuiFormControl-marginNormal {
            margin: 10px 30px 10px 0;
        }

        .continue-button {
            margin: 1em 0;
        }
    }

    .extra-services {

        /* .title {
            font-size: 1.15em;
            font-weight: 400;
            border-bottom: 1px solid $border-gray;
            padding: 10px 0;
            margin: 0;
            margin-top: 40px;
        } */

        .icon {
            font-size: 0.9em;
            margin: 0 0.5em;
            color: $font-secondary;
        }

        .origin-dest {
            font-size: 1em;
        }

        .right-align {
            text-align: end;
        }

        .center-align {
            text-align: center;
        }

        .border-right {
            border-right: 1px solid $border-gray;
        }

        .service {
            font-size: 1.15em;

            .description {
                color: $font-middle;
                text-transform: uppercase;
                vertical-align: text-bottom;

                svg {
                    margin-right: 0.5em;
                }
            }

            .column {
                padding: 10px 10px 10px 0px;
            }

            .price {
                color: $font-secondary;
                font-size: 0.9em;
            }
        }
    }

}

@media only screen and (max-width: 460px) {
    .MuiGrid-grid-xs-6 {
        max-width: 100%;
    }

    .MuiSvgIcon-root {
        font-size: 1rem;
    }

    .MuiInput-root {
        input {
            font-size: 1em;
        }
    }
}