@mixin seatOption {
    height: 0.5em;
    padding-left: 2em;
    position: relative;
    margin: 1em 0;
    font-size: 11px;
}

@mixin seatIcon {
    content: '';
    position: absolute;
    display: block;
    height: 1.2em;
    width: 1.2em;
    top: 0;
    left: 0;
    border-radius: 2px;
    padding: 1px;
}

@mixin seatInfo {
    .seat-info {

        .unavailable {
            @include seatOption;
        }

        .unavailable::before {
            @include seatIcon;
            background-color: $background-light;
        }

        .selected {
            @include seatOption;
        }

        .selected::before {
            @include seatIcon;
            background-color: $background-black;
        }

        .available {
            @include seatOption;
        }

        .available::before {
            @include seatIcon;
            background-color: $background-seat;
        }

        .emergency-exit {
            @include seatOption;
        }

        .emergency-exit::before {
            @include seatIcon;
            background-color: $background-red;
        }

        .info-premium {
            @include seatOption;
        }

        .info-premium::before {
            @include seatIcon;
            content: "\2605";
            color: $font-white;
            text-align: center;
            background-color: $background-seat;
        }
    }
}

@mixin seat {
    background-color: $background-seat;
    font-size: 0.8em;
    font-weight: bold;
    width: 1.7em;
    height: 1.7em;
    margin: 0 2px;
    border-radius: 5px;
    display: inline-flex;
    color: $font-black;
    cursor: pointer;
    text-indent: 0.4em;
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: 'Montserrat';
    border: 2px solid transparent;
}

.seat-modal-root {
    font-family: "Montserrat";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .MuiBackdrop-root {
        background-color: rgba($color: $background, $alpha: 3/4%);
        justify-content: center;
        backdrop-filter: blur(8px);

    }
}

#seat-selection-modal {
    position: relative;
    background-color: $background;
    border-radius: 2em;
    width: 40%;
    min-height: 250px;
    padding: 3em;
    box-shadow: 0px 0px 13.3px 0px #0000001F;

    .modal-title {
        font-weight: 700;
        font-size: 1.7em;
        line-height: 26.82px;
    }

    .modal-description {
        font-weight: 400;
        font-size: 1em;
        line-height: 23.52px;
        padding: 1em 0;
        border-bottom: 1px solid #DFDFDF;

        strong {
            color: #E51F04;
        }

        .restrictionPrice {
            strong {
                font-size: 2em;
                line-height: 37.2px;
                margin-bottom: 0.5em;
            }
        }

        .restriction {
            margin-top: 0.5em;

            li {
                padding-left: 0.8em;
            }
        }

        .modal-terms {
            .checkSelected {
                color: #E51F04;
            }
        }
    }

    .modal-buttons {
        display: inline-flex;
        padding: 1em 0;

        .btnSeat {
            border-radius: 7px;
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            gap: 10px;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 0.9em;
        }

        #cancel-btn {
            margin-left: 2em;
        }
    }
}

.seating {

    .content.locked {
        position: relative;
    }

    .content.locked::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 99;
    }

    @include seatInfo;

    .availabiliry-warning {
        color: $font-secondary;
        margin-bottom: 1em;
        text-align: justify;
    }

    .seat-selection {

        .seat-airplane {
            @include seatInfo;
            max-width: 450px;

            .seat-row {
                text-align: center;
                margin-top: 0.2em;
                margin-bottom: 0.2em;
                font-size: 1.1em;

                .seat-title {
                    @include seat;
                    font-size: 0.8em;
                    width: 3em;
                    background-color: unset;
                }

                .seat-hall {
                    @include seat;
                    width: 2em;
                    background-color: unset;
                    color: rgba(0, 0, 0, 0.0);
                    cursor: default;
                }

                .seat-false {
                    @include seat;
                    width: 0.5em;
                    background-color: unset;
                    color: rgba(0, 0, 0, 0.0);
                    margin: 0 3px;
                    cursor: unset;
                }

                .seat-true {
                    @include seat;
                    width: 0.5em;
                    background-color: $background-red;
                    color: rgba(0, 0, 0, 0.0);
                    margin: 0 3px;
                    cursor: unset;
                }

                .seat-available {
                    @include seat;
                    border: 2px solid $background-seat;
                }

                .seat-unavailable {
                    @include seat;
                    background-color: $background-light;
                    color: $font-light;
                    cursor: unset;
                }

                .seat-selected {
                    @include seat;
                    color: $font-white;
                    background-color: $background-black;
                }

                .premium::before {
                    content: "\2605";
                    font-size: 0.8em;
                    color: $font-white;
                    position: absolute;
                    top: -0.1em;
                    left: -0.5em;
                }

                .seat-hidden {
                    @include seat;
                    background-color: unset;
                    color: rgba(0, 0, 0, 0.0);
                    cursor: unset;
                }
            }
        }
    }

    .title {
        font-size: 2em;
        font-weight: 300;
        padding: 0.5em 0;
    }

    .header {
        text-align: center;

        .subtitle {
            text-align: left;
            font-size: 1.3em;
            padding: 1em 0;
            font-weight: bold;

            svg {
                color: $font-secondary;
                padding: 0 0.5em;
            }
        }

        .section-title {
            padding: 1em 0;
            border-top: 1px solid $border-gray;
            border-bottom: 1px solid $border-gray;
            font-size: 1.2em;
            font-weight: bold;
        }
    }

    .flight-section {
        background-color: $background-gray;
        border-right: 1px solid $border-gray;
        border-bottom: 1px solid $border-gray;
        border-top: 1px solid $border-light;
        padding: 5px 20px;
        position: relative;
        cursor: pointer;

        .title {
            font-size: 1.2em;
            text-align: center;
            font-weight: bold;
        }

        .selected-label {
            font-size: 0.9em;
            font-weight: 300;
        }

        .selected-seats {
            font-size: 0.9em;
            font-weight: 300;
            overflow: hidden;
            height: 2.5em;
            text-transform: uppercase;
        }
    }

    .flight-section::before {
        content: '\2022';
        position: absolute;
        font-size: 4em;
        height: 100%;
        color: $font-primary;
        top: -0.5em;
        left: -0.1em;
    }

    .flight-section::after {
        content: '';
        position: absolute;
        top: 1.5em;
        bottom: -0.1em;
        left: calc(0.7em - 1px);
        width: 3px;
        background-color: $background-dark;
        z-index: 10;
    }

    .flight-section.selected::before {
        color: $font-secondary;
    }

    .passengers {
        border-right: 1px solid $border-gray;

        .passenger {
            padding: 10px 5px;
            border-left: 1px solid $border-gray;
            border-bottom: 1px solid $border-gray;
            border-top: 1px solid $border-light;
            min-height: 80px;
            cursor: pointer;
            text-align: left;

            .type {
                font-size: 0.9em;
                font-weight: 300;
            }

            .name {
                font-weight: bold;
                font-size: 1.05em;
                overflow: hidden;
                white-space: nowrap;
            }

            .delete {
                font-size: 0.8em;
                color: $font-secondary;
                cursor: pointer;
            }

            .delete svg {
                font-size: 0.9em;
                margin-right: 0.5em;
            }

            .delete:hover {
                font-weight: bold;
            }

            .seat-title {
                text-align: center;
                font-size: 0.9em;
                font-weight: 300;
            }

            .seat-number {
                text-align: center;
                font-size: 2em;
                font-weight: normal;
                text-transform: uppercase;
            }

            .checked {
                flex-basis: unset;
                color: $font-white;
                line-height: 1.8em;
                width: 1.8em;
                height: 1.8em;
                font-size: 0.7em;
                background-color: $background-green;
                border-radius: 50%;
                text-align: center;
            }

            .unchecked {
                color: $font-light;
                font-size: 1.2em;
            }
        }

        .passenger.selected {
            position: relative;
            border-left: 3px solid $background-red;
            background-color: $background-gray;
        }

        .passenger.selected::before {
            content: '';
            position: absolute;
            display: block;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid $border-gray;
            top: 20;
            right: -23px;
            transform: scale(1.2, 4.01);
        }

        .passenger.selected::after {
            content: '';
            position: absolute;
            display: block;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid $background-gray;
            top: 20;
            right: -22px;
            transform: scale(1.2, 4.01);
        }

        @media only screen and (max-width: 959px) {
            .passenger.selected::before {
                display: none;
            }

            .passenger.selected::after {
                display: none;
            }
        }


    }

    .next-button {
        margin: 1em;

        svg {
            margin-left: 0.5em;
        }
    }
}

@media only screen and (max-width: 400px) {
    #seat-selection-modal {
        width: 90%;
        padding: 2em;
        overflow: auto;
        height: 90%;
    }
}

@media only screen and (max-width: 460px) {
    #seat-selection-modal {
        width: 90%;
        padding: 2em;
        overflow: auto;
        height: 90%;
    }
}