.fareDetail-row1 {
    background-color: #e0e0e0;
    font-size: 14px;
    font-weight: 300;
}

.fareDetail-row2 {
    font-size: 14px;
    font-weight: 300;
    
}

.fareDetail-row1_border {
    background-color: #e0e0e0;
    font-size: 14px;
    font-weight: 300;
    border-right: 2px solid red;
}

.fareDetail-row2_border {
    font-size: 14px;
    font-weight: 300;
    border-right: 2px solid red;
    
}

.fareDetail-center{
    text-align: center;
}

.fareDetail-left{
    text-align: left;
}

.fareDetail-title {
    font-size: 17px;
    font-weight: 600;
}

.fareDetail-title_border {
    font-size: 17px;
    font-weight: 600;
    border-right: 2px solid red;
}

.fareDetail-margin{
    font-size: 12px;
    font-weight: 300;
    padding-top: 10px;    
}

.fareDetail-margin2{
    padding-top: 10px;    
}