.Timer{
    margin-top: 1em;
    display: inline-grid;
}

.Timer p{
    font-weight: 600;
    font-size: 1.2em;
}

.TimerCount{
    display: inline-flex;
    font-size: 2.4em;
    border-radius: 50%;
    border: 1px red solid;
    height: 4.5em;
    width: 4.5em;
    align-items: center;
    background-color: rgba(255, 0, 0, 0.2);
}

.TimerCountDown{
    justify-content: center;
}

@media only screen and (max-width: 460px) {
    .Timer{
        margin-top: 0;
    }
    
    .TimerCount{
        font-size: 2em;
    }
}
