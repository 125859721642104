.MuiMenuItem-root.passegersMenu, .typePassMenu {
    display: contents;
}

.MuiListSubheader-root.passen {
    border-bottom: 1.26px solid #E4E4E4;
    font-size: 1em;
    font-weight: 700;
    color: black;
}
.noVisible{
    display: none;

    ::before{
        display: none;
    }

    ::after{
        display: none;
    }
}

.MuiMenuItem-root.typePass {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
}

.decrement,
.increment {
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1.26px solid #E51F04;
    border-radius: 999px;
    border-color: #E51F04;
    background: white;
    color: #E51F04;
    width: 32px;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;

    &:hover {
        cursor: pointer;
        border: 1.26px solid white;
        background: #E51F04;
        border-color: white;
        color: white;
    }

    &:focus-visible {
        outline: 0;
    }

    &.increment {
        order: 1;
    }
}

.base-NumberInput-root {
    font-weight: 400;
    color: dark;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    input {
        font-size: 1em;
        font-family: inherit;
        font-weight: 400;
        line-height: 1;
        color: black;
        background: white;
        border: 1px solid gray;
        border-radius: 8px;
        margin: 0 8px;
        padding: 8px 2px;
        outline: 0;
        min-width: 0;
        width: 2rem;
        text-align: center;

        &:hover {
            border-color: red;
        }

        &:focus {
            border-color: red;
        }

        &:focus-visible {
            outline: 0;
        }
    }
}


.btnPass {
    border-top: 1.26px solid #E4E4E4;

    .btnConfirm {
        font-family: 'Inter', sans-serif;
        background-color: #E51F04;
        border-radius: 0.5em;
        cursor: pointer;
        color: white;
        font-size: 1em;
        font-weight: 700;
        width: 80%;
        height: 100%;
        text-transform: capitalize;
    }
}