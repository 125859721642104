.rsrvtn {
    color: #444;
    font-family: 'Lato';
    font-size: 1.5em;
    font-weight: 300;
    margin: 3% 10px 15% 10px;
    text-align: center;
    
}

.hdr-wdg, .wdgt-payment, .wdgt-payment .cnt-high {
    width: 100%;
}
.wdgt-payment .tlt-anim.loading-anim {
    height: auto;
}
.wdgt-payment .tlt-anim {
    width: 100%;
    text-align: center;
}
.wdgt-payment .tlt-anim img {
    float: none;
}
.wdgt-payment .tlt-anim p {
    width: 100%;
    font-weight: bold;
    font-size: 2em;
}
strong {
    font-weight: bold !important;
}

.rappiPNR{
    font-weight: 900;
    color: #e51f04;
}