.rsvtcontainer {
    font-family: "Work Sans", sans-serif;
}

.rsvtns-dtl {
    border-right: 2px solid #c6cccc;
    width: 298px;
    float: left;
}

.rsvtns-wzrd-h1 {
    float: left;
    font-size: 38px;
    margin: 0 12px 20px;
    width: 100%;
    color: #444;
    font-weight: 300;
    text-align: left;

}

.title-rsvt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3em 0 !important;

    img {
        margin: 0 0 20px;
    }

}

.rsvtns-subtitle {
    float: left;
    font-size: 1.8em;
    margin: 0px 0px 1em;
    width: 100%;
    color: #444;
    font-weight: 300;
    text-align: left;
}

.rsvtns-subtitle-center {
    float: left;
    font-size: 24px;
    width: 100%;
    color: #e70c0c;
    font-weight: 300;
    text-align: center;
}

.rsvtns-red-cd-rsvtns {
    font-family: "Work Sans", sans-serif;
    ;
    float: left;
    font-size: 4em;
    margin: 0 0 20px;
    width: 100%;
    color: $font-secondary;
    font-weight: 300;
    text-align: left;
    line-height: 72.8px;
}

.rsvtns-rsvtns-dates {
    float: left;
    font-size: 1.2em;
    color: #000;
    font-weight: 400;
    text-align: left;
    margin-right: 6px;
}

.rsvtns-rsvtns-dates-value {
    float: left;
    font-size: 1.2em;
    color: #000;
    font-weight: 400;
    text-align: left;
    margin-left: 1px;
}

.rsvtns-rsvtns-dates-pddng {
    padding: 0px 1em 6px;
    line-height: 3em;
}

.planes-rsvt {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.rsvtns-dt-plane-to-left {
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
    transform: rotate(-90deg);
}

.rsvtns-dt-plane {
    width: 1.5em;
    height: 1.5em;
    transform: rotate(90deg);
    margin-right: 10px;
}

.rsvtns-red-icon {
    color: #e51f04;
}

.rsvtns-red-icon2 {
    font-size: 30px;
    color: #e51f04;
    padding-left: 7px;
    padding-right: 10px;
}

.rsvtns-ttl-amng {
    float: left;
    font-size: 1.3em;
    margin: 0px 0px 0px;
    width: 100%;
    color: #444;
    font-weight: 400;
    text-align: left;
    line-height: 2em;
}

.rsvtns-ttl-amng-qntt {
    font-size: 3em !important;
    color: #FF1F00;
    font-weight: 300;
    text-align: left;
    line-height: 1.5em;
}

.rsvtns-ttl-amng-qntt-cnts {
    font-size: 14px;
    vertical-align: top;
}

.rsvtns-ttl-amng-qntt-2 {
    font-size: 3em;
    color: #FF1F00;
    font-weight: 400;
    text-align: left;
    line-height: 56.3px;
}

.btn-rsvt-final {
    justify-content: space-between;
}

.rsvtns-bttn {
    margin-top: 1em !important;
}

.rsvtns-dwnl-icon {
    margin: -7px -10px -7px 0;
    font-size: 35px;
}

.rsvtns-bttn-msg,
.rsvtns-bttn-msg1 {
    margin: 0 5px 0 15px;
    text-transform: capitalize;
    font-size: 1.5em;
}

.rsvtns-bttn-msg {
    color: $font-secondary;
}

.rsvtns-bttn-msg1 {
    color: $font-white;
}

.rsrvtns-hd {
    font-family: "Work Sans", sans-serif;
    ;
    float: left;
    margin-bottom: 3em;
    color: #444;
    align-items: center;

}

.price-rsvt {
    display: grid;
    justify-content: center;
}

.arprt-itm {
    font-family: "Work Sans", sans-serif;
    ;
    display: inline-grid;
    justify-content: end;
}

.arprt-dep {
    display: inline-grid;
    align-content: center;
    justify-content: start;
}

.rsvtns-dt-plane-to-left {
    transform: rotate(-90deg) !important;
}

.rsvtns-dtl-brdr {
    border: 1px solid #c6cccc;
}

.rsrvtns-hd2 {
    float: left;
    margin-bottom: 20px;
    color: #444;
}

.rsvtns-border {
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;
    margin: 1em 0 !important;
    font-family: "Work Sans", sans-serif;
    ;
}

.details-rsvt {
    border-top: 1px solid #B3B3B3;
}

.detailCost-rsvt {
    font-family: "Work Sans", sans-serif;
}

.rsvtns-pln-em {
    display: inline-flex;
    width: 3em;
    height: 3em;
    margin-left: 1em;
    transform: rotate(90deg);
}

.rsvtns-dt-prtr-red-em {
    font-size: 1em;
    color: #e51f04;
    font-weight: 400;
    line-height: 20.02px;
}

.rsvtns-dt-prtr-em {
    font-size: 1em;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    padding-bottom: 20px;
    line-height: 20.02px;
}

.rsvtns-dt-prtr-em-2 {
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 2px;
}

.rsvtns-dtl-flht-subtitle-fnt {
    font-size: 1.2em;
    font-weight: 300;
}

.rsvtns-dtl-flht-subtitle-grd {
    padding-bottom: 20px;
    ;
    padding-top: 20px;
}

.rsvtns-dtl-flht-dt-grd {
    padding-top: 5px;
    padding-bottom: 5px;
}

.rsvtns-dtl-flht-dt-grd2 {
    padding-bottom: 15px;
    padding-left: 2em;
    padding-right: 2em;
}

.rsvtns-flht-strong {
    font-family: "Work Sans", sans-serif;
    ;
    color: #e51f04;
    font-size: 3.5em;
    line-height: 1em;
    padding-bottom: 12px;
    float: left;
    text-align: center;
    width: 100%;
    font-weight: 300;
}

.rsvtns-flht-em {
    height: 40px;
    float: left;
    font-size: 1em;
    font-style: normal;
    line-height: 17px;
    padding: 5px 0px;
    text-align: center;
    width: 100%;
    font-weight: 400;
}

.rsvtns-dtl-flght-brdr {
    border-top: 1px solid #c6cccc;
    border-bottom: 1px solid #c6cccc;
}

.rsvtns-dtl-flght-h6 {
    font-size: 0.8em;
    font-weight: 400;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.rsvtns-dtl-flght-h5 {
    font-size: 1em;
    font-style: italic;
    font-weight: 400;
    color: #919191;
    margin-left: 3px;
}

.rsvtns-dtl-flght-h5-bld {
    font-size: 0.8em;
    font-weight: 600;
    font-style: normal;
}

.rsvtns-dtl-flght-h5-2 {
    font-size: 1.2em;
    font-weight: 700;
    font-style: normal;
    padding-bottom: 20px;
}

.rsvtns-dtl-flght-h3 {
    font-size: 1.5em;
    font-weight: 300;
    text-align: start;
    padding-top: 1em;
}

.rsvtns-gray-icon {
    color: #909090;
}

.rsvtns-dtl-flght-h3-2 {
    font-size: 2em;
    font-weight: 300;
    text-align: start;
    padding-bottom: 10px;
}

.rsvtns-dtl-flght-h4 {
    font-size: 1em;
    font-weight: 400;
    text-align: start;
    padding-top: 5px;
    line-height: 1.5em;
    text-transform: capitalize;
}

.rsvtns-dtl-flght-h4-bld {
    font-size: 1.4em;
    font-weight: 700;
    text-align: start;
    padding-bottom: 0.6em;
}

.rsvtns-dtl-flght-h4-bld2 {
    font-size: 1.4em;
    font-weight: 700;
    text-align: start;
    padding-top: 5px;
    padding-bottom: 0.6em;
}

.rsvtns-grid-msg {

    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fefcd1;
}

.rsvtns-grid-msg1 {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #F2F9FE;
    color: #444444;
    font-weight: 400;
    padding-left: 17px;
    font-size: 1.2em;
    border: 1px solid #CEE4F5;
    border-radius: 9px;
    display: flex;
    align-items: center;
    margin-bottom: 2em !important;

    a {
        display: contents;
        color: #444444;
    }

    img {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
    }
}

.rsvtns-grid-pddng-top {
    margin-top: 10px;
}

.rsvtns-grid-pddng-bottom {
    padding-bottom: 30px;
}

.padding-costs {
    padding: 1em;
}

.rsvtns-asistar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    img{
        width: 50%;
        height: auto;
    }
}

//STORE
.store-head-detail,
.store-detail,
.store-OP,
.store-img {
    display: flex;
}

.store-head-detail,
.store-detail {
    border-bottom: 1px solid #c6cccc;
    margin-bottom: 1rem;
}

.store-date,
.store-total h4,
.store-howTo,
.store-casher {
    font-size: 1.5em;
    display: table;
}

.store-date h4,
.store-total h4,
.store-howTo h4,
.store-casher h4,
.store-detail p,
.store-OP {
    margin: 0.5em;
}

.store-date img {
    margin: 0 6em;
    height: 3em;
}

.store-date h6,
.store-total h6 {
    width: 70%;
    margin: 0.5em;
    font-weight: initial;
}

.store-date h6 {
    font-size: 0.7em;
}

.store-total h6 {
    font-size: 1em;
}

.store-date p {
    font-size: 0.8em;
    margin: 0px 8em;
}

.store-price {
    color: #e51f04;
    font-size: 2.5em;
    margin: 0.2em;
    font-weight: 300;
}

.store-paynet {
    display: flex;
    margin: 0.5rem;
}

.store-paynet p {
    width: 20%;
    font-size: 0.6em;
    margin: 0%;
}

.store-paynet img {
    width: 36%;
}

.store-howTo {
    border-right: 1px solid #c6cccc;
}

ol li::marker {
    color: #e51f04;
}

ol li,
.store-detail p {
    font-size: 0.8em;
}

.store-OP-img img {
    width: 5em;
    height: 2em;
}

//SPEI
.red {
    color: #e51f04;
    margin-left: 0.2em;
}

.speidue {
    font-weight: 500;
}

.banks {
    display: inline-flex;
    border-top: 1px solid #c6cccc;
    border-bottom: 1px solid #c6cccc;
}

.morebanks {
    text-align: center;
    align-self: center;
}

.zenki-rsv {
    padding: 4em;
}

.mdRes {
    display:none;
    width: 50%;
    height: 50%;
    margin: 1vw 18vw;
}

@media only screen and (max-width: 760px) {
    .MuiGrid-container {
        .rsrvtns-hd2 {
            display: table;
        }
    }

    .rsvtns-dtl-brdr {
        max-width: 100% !important;
    }

    .store-head-detail {
        display: block;
    }

    .mdRes {
        display: none;
        width: 50%;
        height: 50%;
        margin: 1vw 18vw;
    }
}

@media only screen and (max-width: 460px) {
    .MuiGrid-container {
        .rsrvtns-hd2 {
            display: table;
        }
    }

    .MuiGrid-spacing-xs-2 {
        width: calc(96% + 16px) !important;
    }

    .rsvtns-dtl-brdr {
        max-width: 100% !important;
    }

    .MuiGrid-spacing-xs-2 {
        margin: 0px !important;
        /*sorry for use the important but is the only way*/
    }

    .rsvtns-red-cd-rsvtns {
        font-size: 2.4em;
    }

    .store-head-detail {
        display: block;
    }

    .store-detail {
        display: block;
    }

    .store-date,
    .store-total,
    .store-howTo,
    .store-casher {
        font-size: 1.3em;
    }

    .store-paynet,
    .store-howTo {
        max-width: 100% !important;
    }

    .store-howTo {
        border-right: 0;
        border-bottom: 1px solid #c6cccc;
    }

    .banks {
        display: inline-block;
    }

    .banks img {
        width: 50%;
        height: auto;
    }

    .mdRes {
        display: none;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .mdBannmed {
        display: none;
    }

    .price-rsvt{
        justify-content: start;
    }

    .rsrvtns-hd {
        margin: 0 1em;
    }
}