@import './colors_theme.scss';

.app {
    background-color: $background;
    color: $font-primary;
    font-size: 12pt;
    font-family: 'Montserrat', sans-serif;
}

//IMPORT ALL SCSS STYLES
@import './header.scss';
@import './body.scss';
@import './footer.scss';

//Home styles
@import './Home/home.scss';
@import './Home/promotions.scss';
@import './Home/services.scss';
@import './Home/destination.scss';

//Booking styles
@import './Booking/widget.scss';
@import './Booking/passengerSelect.scss';
@import './Booking/calendar.scss';
@import './Booking/passengers.scss';
@import './Booking/seating.scss';
@import './Booking/reservations.scss';
@import './Booking/faresDetail.scss';


//CMS styles
@import './CMS/promotions.scss';
@import './CMS/socios.scss';
@import './CMS/destinations.scss';

//reset styles
@import './CMS/Reset/reset.scss';

//commons
@import './ShoppingCart/shopping-cart';
@import './Payment/payment';
@import './Common/Checkbox';
@import './Common/currency-format.scss';
@import './Common/timer.scss';
@import './Common/mdbanner.scss';

@import './CMS/content.scss';

//Invoice styles
@import './invoice/invoice.scss';

//Marketplace styles
//@import './Marketplace/marketplace.scss';

//3DSecure
@import './3DSecure/3dsecure.scss';

