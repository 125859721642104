.widget {
    .widget-vertical {
        font-size: 1em;
        font-weight: 300;
        color: $font-black;
        background-color: $background;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0.2em 0.2em 1.5em rgba(0, 0, 0, 0.3);
    }


    .widget-vertical .widget-content {
        margin: 15px 25px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        align-items: baseline;
    }

    .widget-content .label {
        margin-right: 0.3em;
        color: $font-secondary;
        font-weight: 600;
    }

    .widget-content .full-size {
        width: 100%;
        padding: 0px;
        margin: 0px;

        &:before {
            border: none;
        }

        &:after {
            border: none;
        }

        &:hover {
            //border: none;

            &:before {
                border: none;
            }

            &:after {
                border: none;
            }
        }
    }

    .widget-content .size-15 {
        width: 15%;
    }


    .widget-tabs {
        padding: 7px 10px;
        background-color: $background-widget-tabs;
        cursor: pointer;

        a {
            text-decoration: none;
            color: inherit;
        }
    }

    .widget-tabs:hover {
        font-weight: 400;
    }

    .widget-tabs.selected {
        background-color: inherit;
        font-weight: inherit;
    }


    .route {
        //width: 60%;
        display: inline-flex;
        align-content: center;
    }

    .iataOrigin,
    .iataDestination {
        width: 50%;
    }

    .lblOrigin,
    .lblDestination,

    .route {
        //width: 60%;
        display: inline-flex;
        align-content: center;
    }

    .iataOrigin,
    .iataDestination {
        width: 50%;
    }

    .lblOrigin,
    .lblDestination,
    .date-picker-label,
    .lblCurrency,
    .lblTrip,
    .lblPassengers {
        display: flex;
        font-size: 0.6em;
        justify-content: flex-start;
        font-family: "Inter";
        color: #767676;
        line-height: 10.89px;
        font-weight: 700;
    }

    .origin-container {
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-right: 1px solid #a6a6a6;
            transform: skewX(-15deg);
        }
    }

    .select-origin {
        >svg {
            color: $font-secondary;
        }
    }

    .select-destination {
        >svg {
            color: $font-secondary;
        }
    }

    .date-picker-depa,
    .date-picker-retu {
        width: 100%;
    }

    .date-picker,
    .passengers {
        align-content: center;
        justify-content: center;
    }


    .buttonWid {
        width: 15%;
        position: relative;
        top: 2.5vh;
        left: -2em;
    }

    .tripType {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .check-left,
        .check-right {
            align-content: center;
            text-align: center;
            cursor: pointer;
        }

        .checked {
            color: $font-white !important;
            background-color: $background-red;
            font-weight: 400;
            line-height: 24.2px;
            height: 2.25em;
        }
    }

    .currencyBox {

        .check-left,
        .check-right {
            align-content: center;
            text-align: center;
            cursor: pointer;
        }

        .checked {
            color: $font-white !important;
            background-color: $background-red;
            font-weight: 400;
            line-height: 24.2px;
            height: 2.25em;
        }
    }

    .tripSelec {
        width: 20%;

        .tripType {
            width: 100%;

            .trip-select {
                background: $background;
                width: 100%;
                font-size: 1em;
                color: $font-primary;
                border: 1px solid $background-gray-dark;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                justify-items: stretch;
                flex-wrap: wrap;
                flex-direction: row;
                text-align: justify;

                svg {
                    color: #c71900;
                    font-size: 1.3em;
                    top: calc(90% - 25px);
                }

                p {
                    font-size: 1em;
                    color: $font-black ;
                    font-weight: 400;
                    margin: 0.2em;
                }
            }
        }
    }


    .currencyWid {
        width: 15%;
    }

    .date-picker {
        width: 40%;
    }

    .passengers {
        width: 30%;
    }



    .route .border-right {
        border-right: 1px solid $border-blue;
        font-size: 14px;
        padding: 0px 10px;
    }

    .route .plane-icon {
        color: $font-white;
        font-size: 18px;
    }

    .route .route-v-line {
        margin-left: 13px;
        border-right: 1px solid $background-blue;
        height: 114px;
        width: 7px;
    }

    .arprt-pln {
        position: relative;

        .origin-pln-em {
            position: absolute;
            top: 20px;
            left: 15px;
        }

        .destination-pln-em {
            position: absolute;
            transform: rotate(180deg);
            top: 50px;
            left: 10px;
        }
    }

    .route .border-left {
        border-left: 1px solid $border-blue;
        font-size: 14px;
        padding: 0px 10px;
    }

    .route .MuiSelect-select.MuiSelect-select {
        font-size: 0;
    }

    .route .MuiSelect-icon {
        color: $background-red-alter;
        font-size: 1.3em;
        transform: translateY(-70%);
    }

    .planeRoute {
        color: $background-gray-dark;
        align-self: center;
    }

    .date-picker .date-picker-label {}

    .widget-vertical .iata-code,
    .destination-text-container {
        line-height: 2em;
        font-size: 1em;
    }

    .divider {
        border-right: 1px solid $background-widget;
    }

    .text-field {
        background-color: $background-disabled !important;
        width: 100%;
        font-weight: 700;
    }

    .input {
        .MuiOutlinedInput-notchedOutline {
            border: none !important;
        }
    }

    .date-picker {
        padding: 0 0.5em;
    }

    .date-picker .month {
        font-size: 1em;
    }

    .date-picker .date-picker-single-trip {
        color: $background-gray-dark;
        opacity: 0.6;
        font-weight: 300;
        cursor: not-allowed;
    }

    .date-picker .div-container-date-picker {
        width: 100%;
        border: 0;
        margin: 0;
        display: inline-flex;
        padding: 0;
        position: relative;
        min-width: 0;
        flex-direction: column;
        vertical-align: top;
        margin-bottom: auto;
        font-size: 1.3em;
        margin-right: 0.7em 0.5em;
        background-color: $background;
        border: 1px solid $background-gray-dark;
        border-radius: 4px;
        height: auto;

    }

    .date-picker .date-picker-dates-container {
        display: inline-flex;
        width: 100%;
    }

    .date-picker .date-picker-input-container {
        margin: 0;
        border-radius: 4px;
        border: 0;
        padding: 0.2em;
        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 1em;
    }

    .date-picker .date-picker-origin-disabled {
        font-size: 1em;
        color: $background-gray-dark;
        opacity: 0.6;
        font-weight: 300;
        cursor: not-allowed;
    }

    .date-picker .date-picker-origin {
        cursor: pointer;
        font-size: 1em;
    }

    .date-picker .date-picker-destionation-disabled {
        background-color: $background;
        border: 1px solid $background-red;
        border-radius: 4px 4px;
        font-size: 1em;
        color: $background-gray-dark;
        opacity: 0.6;
        font-weight: 300;
        cursor: not-allowed;
    }

    .date-picker .date-picker-destination {
        cursor: pointer;
        border: 1px solid $background-red;
        background-color: $background;
        border-radius: 4px 4px;
        font-size: 1em;
    }


    .MuiDateRangeCalendar-dayDragging {
        background-color: rgba(255, 74, 74, 0.6);
        color: $font-white;
    }

    .MuiMenuItem-root .passegersMenu {
        display: contents;
    }

    .passen {
        border-bottom: 1px solid $background-black;
    }

    .typePass {
        display: flex;
        align-items: center;
    }

    .passengers .info-passenger {
        font-size: 16px;
        float: left;
        text-align: center;
        margin-bottom: 0;
        width: 100%;
        color: $font-secondary;
        font-weight: 900;
    }

    .passengers .info-age {
        font-size: 10px;
        text-align: center;
        font-weight: 400;
        width: 100%;
    }



    .passengers .without-border {}

    .passengers .MuiSelect-select.MuiSelect-select {
        padding-right: 0;
        font-size: 1em;
        font-weight: 600;
    }

    .passengers .MuiSelect-icon {
        color: $background-red;
        font-size: 1.3em;
        margin-right: 5px;
    }

    .passengers ::-webkit-inner-spin-button {
        transform: scale(1.5);
    }

    .widget-content .country-flag {
        height: 0.9em;
    }

    .italic-input .MuiInputLabel-formControl {
        top: -10px;
    }

    .currencyCon {
        width: 100%;
    }

    /*.currencyWid*/
    .currency-select {
        font-size: 1em;
        background-color: $background;
        border: 1px solid $background-gray-dark;
        border-radius: 4px;
        height: auto;
        display: flex;
        width: 100%;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        text-align: justify;

        svg {
            color: #c71900;
            font-size: 1.3em;
            top: calc(90% - 25px);
        }


        b {
            font-size: 1em;
            color: $font-black ;
            font-weight: 400;
            margin: 0.5em 0.5em;
        }
    }

    .search.Mui-disabled {
        color: $font-middle;
        background: none;
        background-color: $background-gray-dark;
    }

    @media only screen and (max-width: 1024px) {
        .widget-vertical {
            font-size: 1.2em;
        }

        //.route,
        .date-picker,
        .passengers {
            width: 34%;
            height: auto;
        }

        .currencyWid {
            width: 18%;
        }

        .tripSelec,
        .buttonWid {
            width: 28%;
            align-content: center;
            justify-content: center;
        }

        .widget-vertical .check-left,
        .widget-vertical .check-right {
            padding: 0px;
        }

    }

    @media only screen and (max-width: 460px) {

        .widget-vertical .tripSelec,
        .date-picker,
        .passengers,
        .currencyWid,
        .buttonWid {
            width: 100%;
            align-content: center;
            justify-content: center;
        }

        .widget-vertical .check-left,
        .widget-vertical .check-right {
            padding: 0px;
            font-weight: 800;
        }
    }

    @media only screen and (max-width: 400px) {
        .widget-vertical {
            font-size: 1.3em;
        }

        .widget-vertical .tripSelec,
        .date-picker,
        .passengers,
        .currencyWid,
        .buttonWid,
        .route {
            width: 100%;
            align-content: center;
            justify-content: center;
            flex-wrap: wrap;
        }



        .widget-vertical .check-left,
        .widget-vertical .check-right {
            padding: 0;
            font-weight: 800;
        }

        .date-picker-blocks {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    /**
        Breakpoints with sizes of MUI
    **/

    @media only screen and (min-width: 461px) and (max-width: 960px) {
        .tripType {

            .checked {
                height: 2em;
                font-weight: 500 !important;
            }
        }

        .currencyBox {

            .checked {
                height: 2em;
                font-weight: 500 !important;
            }
        }

    }

}

.tar-date-picker-container {

    /*
    
    Se usan clases para modificar los estilos, 
    https://v5.mui.com/x/api/date-pickers/date-range-picker-day/#date-range-picker-day-classes-day
    
    */
    .MuiDateRangePickerDay-rangeIntervalDayHighlight {

        background-color: transparent !important;
        border-radius: 0;

        .MuiDateRangePickerDay-rangeIntervalPreview {
            border-color: $background-date-inside-interval-trip-calendar;
        }
    }

    button.Mui-selected {
        color: $font-white !important;
        background-color: $font-secondary !important;
        border-radius: 0;
    }

    button.MuiButtonBase-root {
        font-weight: 500;
    }

    button.MuiDateRangePickerDay-dayInsideRangeInterval {
        color: $font-black !important;
        background-color: $background-date-inside-interval-trip-calendar !important;
        border-radius: 0;
    }

    button.Mui-disabled {
        font-weight: 400;
        color: $background-titles !important;
    }

    .tar-date-picker-container-single-selected button.Mui-selected {
        background-color: $font-secondary !important;
    }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    min-height: 70px;
    max-height: 310px;

    &hover {
        background-color: $background-red
    }
}

.MuiMenuItem-root.Mui-selected,
.MuiMenuItem-root.Mui-selected:hover {
    background-color: $background-red !important;
    color: $font-white !important;
}

.MuiList-padding {
    width: 100%;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.v-line .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    margin-left: -5px;
    width: 80px !important;
    height: 100px !important;
    overflow-y: visible !important;
}

.v-line .MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    background-color: $background-red !important;
    ;
    color: $font-white;
}

.v-line .MuiList-padding {
    width: 100%;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}



.widget-resume {
    width: 100%;

    .bkn-rtssrch {
        background-color: #ffffff;
        float: left;
        padding: 10px 0px 10px 10px;
        color: $font-primary;
        font-size: 1em;
    }

    .bkng-wzrd-h1 {
        float: left;
        font-size: 34px;
        margin: 30px 0px 20px 0px;
        width: 100%;
        color: $font-primary;
        font-weight: 300;
        text-align: left;
    }

    .bkn-h6 {
        margin-top: 0px;
        width: 100%;
        text-align: center;
        color: $font-primary;

        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .passengers-title {
        font-size: 16px;
        font-weight: 100;
        margin-left: 20px;
    }

    .clndr-date-info {
        color: $font-primary;
        font-size: 12px;
        font-weight: 300;
        display: inline;
    }

    .bkn-h6-margin-bs {
        margin-bottom: 5px;
    }

    .bkn-rtssrch-strong {
        color: $font-primary;
        font-size: 36px;
        line-height: 38px;
        padding-bottom: 0px;
        float: left;
        letter-spacing: -1px;
        text-align: center;
        width: 100%;
        font-weight: 300;
    }

    .bkn-rtssrch-em {
        height: 40px;
        float: left;
        font-size: 14px;
        font-style: normal;
        line-height: 17px;
        padding: 5px 0px;
        text-align: center;
        width: 100%;
        font-weight: 300;
    }

    .link {
        float: right;
        color: $font-secondary;
        display: block;
        font-weight: 400;
        padding: 5px 0;
    }

    .link-float-l {
        float: left;
    }

    .link-float-r {
        float: right;
        margin-top: 10px;
        margin-right: 5px;
    }

    .bkn-lst {
        float: left;
        margin-left: 30px !important;
    }

    .bkn-lst-itm {
        float: left;
        width: 100%;
        color: $font-primary;
        font-size: 16px;
        font-weight: 300;
    }

    .circle-icon-w {
        color: $font-secondary;
        float: left;
        font-size: 8px;
        padding: 7px 5px 13px 0;
    }

    .search-icon {
        margin-right: 5px;
    }

    .red-icon-widget {
        color: $font-secondary;
        font-size: 20px;
    }

    .bkn-flsinf {
        font-size: 16px;

    }

    .arprt-itm {
        // border-right: 2px solid #c6cccc;
    }

    .arprt-pln {
        // margin: 15px 132px 0;
        // position: absolute;
        width: 30px;
        font-weight: 300;
    }

    .arprt-pln-em {
        float: left;
        font-size: 28px;
        margin-bottom: 5px;
        text-align: center;
        position: relative;
        top: -8px;
        left: 7px;
        //width: 100%;
    }

    .detail-area {
        border-right: 2px solid #c6cccc;
    }

    .not-roundtrip-date {
        margin: auto;
        margin-left: 0px;
    }

    .arprt-v-line {
        margin-left: 13px;
        height: 100px;
        width: 2px;
    }

    .clndr-dt-span {
        color: $background-black;
        font-size: 16px;
        // float: left;
        font-weight: 300;
    }

    .clndr-dt-strong {
        width: 100%;
        font-size: 14px;
        line-height: 14px;
    }

    .clndr-dt-plane {
        font-size: 10px !important;
        margin-right: 10px;
    }

    .clndr-dt-plane-to-left {
        font-size: 10px !important;
        margin-right: 10px;
        transform: rotate(180deg);
    }

    .clndr-pb {
        padding-bottom: 30px;
    }

    .clndr-px {
        padding-left: 20px;
    }

    .disabled {
        cursor: default;
        -moz-opacity: .2;
        -khtml-opacity: .2;
        opacity: .2;
        -ms-filter: alpha(opacity=(20));
        filter: alpha(opacity=20);
    }

}