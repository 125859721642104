.ibe-shopping-cart {
  max-width: 80%;
  background-color: white;
  margin: 2em 0 2em 4em;

  .content {
    padding: 0 10px;
  }

  .title-car {
    font-size: 1em;
    margin: 0.5em;
  }

  .page-title {
    font-weight: 700;
    padding: 0;
  }

  .currencyCal {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 400;

    strong {
      width: 44px;
      height: 23px;
      background-color: #D6F6CE;
      border: 1px solid #6BC953;
      color: #6BC953;
      border-radius: 4px;
    }
  }

  .headerShop {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  .section {
    text-transform: none;
    width: 100%;

    h2 {
      margin: 0;
      text-align: left;
      width: 100%;
      white-space: nowrap;
    }

    .section-icon {
      margin-right: 10px;
      font-size: 0.8em;

      .red-icon {
        color: $font-secondary;
      }
    }

    .section-header {
      color: $font-secondary;
      font-size: 1em;
      font-weight: 500;
      line-height: 1.2em;
    }

    .section-header-content {
      display: block;
      text-align: right;
    }

    .caret {
      float: right;
      font-size: 15px;
      margin: 3px 10px 0 0;
    }

    .expand-section {
      color: $font-primary;
      font-weight: 300;
      font-size: 1em;
      text-align: left;
      overflow: hidden;
      transition: height 0.2s ease-out;
      width: 100%;

      .expanded-value {
        display: flex;
        justify-content: space-between;
      }

      h4 {
        margin-bottom: 0;
      }
    }

    &.passengers {

      .passenger {
        padding: 8px 0;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &.total {
      background-color: $background-final-price;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      position: relative;
      width: 20.7em;
      height: 4.5em;
      left: -0.6em;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;

      * {
        color: white;
        font-weight: 700;
      }

      h2 {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0px 3em 0 1em;
      }
    }

    &.summary {

      .section-header {
        font-weight: 500;
        color: $font-secondary;
      }

      * {
        color: $font-primary;
      }

      .expand-section {
        font-weight: 400;
      }
    }

    &.flight {
      &.return {}
    }

    .extra-services-content {
      width: calc(100% + 16px);
      margin-left: -8px;
      margin-bottom: -3px;
      padding-left: 8px;
      padding-right: 8px;

      .extra-services {
        text-align: left;

        .extra-service {

          .service-header {

            .icon {
              display: block;
              float: right;
            }

            .total {
              font-size: 1.1em;
            }

          }

          .amount {
            display: block;
            float: right;
          }
        }
      }
    }

  }

  .MuiCardContent-root:last-child {
    padding: 0 !important;
  }

  .detail-header {
    color: #909090;
    font-size: 13px;
    text-align: left;
    width: 100%;
  }
}

@media only screen and (max-width:1400px){
  .ibe-shopping-cart {
    max-width: 95%;

    .section.total {

      h2{
        justify-content: flex-start;
        margin: 0px 0em 0 1em;

        .section-header{
          margin-right: 0.3em;
        }
      }
    }

    
  }
}


@media only screen and (max-width: 1024px) {
  .ibe-shopping-cart {
    max-width: 100%;
    margin: 1em 0 0 0.5em;

    .section.total {

      h2{
        justify-content: flex-start;
        margin: 0px 0em 0 0.6em;

        .section-header{
          margin-right: 0.3em;
        }

        .section-header-content{
          .currency-amount{
            font-size: 0.9em !important;
          }
        }
        
      }
    }

    .content {
      padding: 0px;
    }

    .section {
      .expand-section {
        font-size: 0.8em;
      }
    }
  }

  .section-header {
    font-size: 0.8em !important;
  }

  
}

@media only screen and (min-width:461px )and (max-width: 960px) {
  .ibe-shopping-cart {
    max-width: 80%;
    margin: 2em;

    .section.total {

      h2{
        justify-content: flex-start;
        margin: 0px 0em 0 1em;

        .section-header{
          margin-right: 0.5em;
        }
        
      }
    }
  }

}

@media only screen and (min-width:320px )and (max-width: 460px) {
  .ibe-shopping-cart {
    max-width: 80%;
    margin: 2em;

    .section.total {
      width: 31.7em;
      left: -1.6em;

      h2{
        justify-content: space-between;
        margin: 0 2em;

        .section-header{
          margin-right: 0.5em;
        } 

        .section-header-content{
          .currency-amount{
            font-size: 1em !important;
          }
        }
      }
    }
  }
}
