.ibe-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: justify;

  .checkbox {
    background-image: url('/assets/img/check_none.png');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100%;
    cursor: pointer;
    height: 31px;
    width: 31px;

    &.checked {
      background-image: url('/assets/img/check_on.png');
    }

    &.disabled {
      background-image: url('/assets/img/check_off.png');
      cursor: not-allowed;
    }

    &.input-error {
      background-color: $background-error !important;
      border: solid 1px $border-red !important;
    }
  }

  .label{
    cursor: pointer;
  }

}

@media only screen and (max-width: 460px) {
  .ibe-checkbox {
    display: grid;
  }
}
