.content-container {
    font-size: 1em;
    margin: auto;
    padding-bottom: 2em;

    li {
        margin: 1em 0;
    }

    .content {
        margin-top: calc(4em + 40px);
        border-left: 1px solid $border-light;
        padding-left: 3%;
        text-align: justify;

        a {
            display: inline;
        }

        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }
    .menu-list {
        font-size: 1em;
    }
    
    .menu-list .title {
        font-size: 2em;
        padding: 1em 0;
    }
    
    .menu-list .menu-item {
        padding: 20px;
        color: inherit;
        font-weight: 300;
        text-transform: uppercase;
    }
    
    .menu-item a {
        text-decoration: none;
        color: inherit;
    }
    
    .menu-item a:hover {
        color: $font-secondary;
    }
    
    .menu-item.selected {
        background-color: $background-promotion;
    }
}
@media only screen and (max-width: 899px) {
    .content-container {
        padding: 0 1%;
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 460px) {
    .content{
        max-width: 98% !important;
        flex-basis: 98% !important;
    }

    .MenuIconMov{
        margin-top: 1vh;
    }

    .content-container {
        padding: 0 1%;
        font-size: 0.8rem;
    }
    
}