.destinations {
    .MuiMobileStepper-root {
        background-color: transparent !important;
        position: absolute !important;
        color: white !important;
        bottom: -3em;
        align-items: flex-end;
        z-index: inherit !important;

        .MuiMobileStepper-dot {
            background-color: $dot-not-active;
            width: 12px;
            height: 12px;
        }

        .MuiMobileStepper-dotActive {
            background-color: $font-secondary;
        }
    }
}

.desLbl {
    color: $font-black;
    font-size: 2em;

    strong {
        color: $font-secondary;
    }
}

.destStep {
    display: flex;
}

.destCon {
    overflow: hidden;
}

.desbanner {
    width: 40%;
    height: 400px;

    .nameDes {
        font-family: 'Montserrat', sans-serif !important;
        text-align: center;
        height: 5em;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, rgba(4, 0, 0, 0) 0%, #000000 100%);
        border-radius: 12px;
    }

    .nameDes p {
        font-family: 'Montserrat', sans-serif;
        margin-left: 1em;
        color: $font-white;
        font-size: 1.5em;
        font-weight: 600;
    }

    a {
        font-family: 'Montserrat', sans-serif;
        height: 100%;
        position: relative;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 12px;
    }

}


.desBtnRigth,
.desBtnLeft {
    position: absolute !important;
    top: 50%;
    z-index: 1;
}

.desBtnRigth svg,
.desBtnLeft svg {
    font-size: 5em;
    color: $font-secondary;
}

.desBtnRigth:disabled,
.desBtnLeft:disabled {
    svg {
        color: $font-middle;
        opacity: 0.6;
    }
}

.desBtnRigth {
    left: -6%;
}

.desBtnLeft {
    right: -6%
}

@media only screen and (max-width: 1200px) {
    .desBtnRigth {
        left: 0;
    }

    .desBtnLeft {
        right: 0;
    }
}


@media only screen and (max-width: 899px) {

    .desBtnRigth {
        left: 0;
    }

    .desBtnLeft {
        right: 0;
    }

    .desBtnRigth svg,
    .desBtnLeft svg {
        font-size: 4em;
    }

    .desbanner {
        height: 200px;
    }
}

@media only screen and (max-width: 599px) {

    .desBtnRigth {
        left: 0;
    }

    .desBtnLeft {
        right: 0;
    }

    .desBtnRigth svg,
    .desBtnLeft svg {
        font-size: 4em;
    }
}