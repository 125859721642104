//BASE COLORS
$background: #fff;
$background-dark: #3b3b3b;
$background-light: #dedfe1;
$background-red: #e51f04;
$background-blue-dark: #041fe5;
$background-red-dark: #a60000;
$background-red-alter: #c71900;
$background-red-light: #FFE7E4;

$background-error: #f9f9c7;
$background-blue: #299dbb;
$background-widget: rgb(224, 225, 226);
$background-arrow-select: #c4ff6c;
$background-disabled: #416072;
$background-widget-tabs: #416072;
$background-promotions: #f7f7f7; 
$background-clndr-date-picker: rgb(238,238,238);
$background-promotion: #e7e7e7;
$background-promo-selected: #d7d7d7;
$background-final-price: #606061;
$background-seat: #fdb400;
$background-black: #000;
$background-green: #00C000;
$background-gray: #f5f5f5;
$background-gray-dark: #a6a6a6;
$background-services: #C9D3E2;
$background-titles:#a8a9a8;
$background-date-inside-interval-trip-calendar:#FFDDD9;

$font-primary: #444;
$font-secondary: #e51f04;
$font-bullets: #810308;
$font-middle: #777;
$font-light: #ccc;
$font-social-icon: #929292;
$font-white: #fff;
$font-black: #000;
$font-footer:#9F2019;
$font-titles:#393d42;

$border-dark: #3b3b3b;
$border-light: #efefef;
$border-white: #fff;
$border-blue: #299dbb;
$border-red: #e51f04;
$border-gray: #ccc;

$red-plane: #e51f04;
$red-circle: #e51f04;
$gray-circle: #999;

$dot-not-active: #FF00004D
