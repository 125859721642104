.widget {
    position: sticky;
    z-index: 2;
    top: 0;
}

/* .promotions {
    min-width: 100%;
    margin-left: -12.5%;
} */

.services {
    padding: 4em 0px;
}

@media only screen and (min-width: 1280px) {
    /*     .promotions {
        min-width: 100%;
        margin-left: 0%;
    } */
}

@media only screen and (max-width: 899px) {
    /*     .promotions {
        min-width: 105%;
        margin-left: -2.5%;
    } */
}

@media only screen and (max-width: 599px) {
    .widget {
        position: initial;
        margin: auto;
        width: 100% !important;
    }

    /*     .promotions {
        min-width: 100%;
        margin: 0;
    } */
}