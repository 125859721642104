.body {
  padding: 0 130px;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;

  input {
    font-weight: 300;

    &::-webkit-input-placeholder {
      font-style: italic;
    }

    &:-moz-placeholder {
      font-style: italic;
    }

    &::-moz-placeholder {
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-style: italic;
    }

    &.input-error {
      background-color: $background-gray !important;
      border: solid 1px $border-red !important;
    }
  }

  .errorP {
    color: $border-red;
    font-size: 0.8em;
    margin: 0;
    margin-block: 0;
  }
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: $background-dark !important;
  color: $font-white;
}

@media only screen and (max-width: 1200px) {
  .body {
    padding: 0 2%;
  }
}


@media only screen and (max-width: 899px) {
  .body {
    padding: 0 2%;
  }
}

@media only screen and (max-width: 599px) {
  .body {
    padding: 0;
  }
}