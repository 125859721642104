.services .service {
    .serviceTit{
        width: 100%;
        height: 8em;
        background: $background-promotions;
        display: flex;
        border-radius: 13px;
        justify-content: flex-start;
        align-items: center;
        padding: 1em;

        p{
            font-size: 1.18em;
            cursor: pointer;
            color:$font-black;
            margin: 10px;
        }
        img{
            width: 30px;
            height: 30px;
        }
    }
}

.service .image {
    max-width: 100%;
}

.image img {
    border-bottom: 10px solid $background-red;
}

.service .title {
    font-size: 1.2em;
}

.service .content {
    font-weight: 300;
    margin-bottom: 30px;
    text-align: justify;
}

.service .link {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.title a {
    color: $font-secondary;
    text-decoration: none;
}