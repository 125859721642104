.destinations {
    .title-destination {
        background-color: $background-titles;
        color: $font-titles;
        display: flex;
        justify-content: center;
        font-size: 2em;
        font-weight: 900;
    }

    .content {

        display: flex;
        flex-direction: column;
        margin: 1em 0;
        padding-bottom: 1.5em;
        border-bottom: 1px solid $border-dark;

        &-container-title {
            border-bottom: 1px solid $border-red;
            width: 40%;
        }

        &-title {
            color: $font-titles;
            font-style: italic;
            margin: 0 0.5em;
            font-size: 2em;
        }

        &-date{
            margin: 0.5em 0;
            font-size: 1.3em;
        }

        &-text{
            margin: 1em 0;
            white-space: pre-line;
        }
        
        &-image{
            width: 100%;
            max-height: 50em;
            object-fit: contain;
            margin: 1em 0
        }
    }
}

@media only screen and (max-width: 899px) {
    .content {
        &-container-title {
            width: 50% !important;
        }
    }

}

@media only screen and (max-width: 460px) {
    .content {
        &-container-title {
            width: 70% !important;
        }
    }
}

@media only screen and (max-width: 400px) {
    
}