.newInvoice,
.searchInvoice,
.dataInvoice {
    margin: 2vw;
    padding: 2vw;
    text-align: center;
    font-size: 3vh;
}

input {
    font-size: 0.85em;
    margin: 10px 30px 10px 0;
    color: $font-middle;
    padding: 5px 20px;
    font-weight: 300;
}

.MuiInput-root {
    input {
        margin: unset;
        padding: unset;
        font-size: 1.2em;
    }
}

.MuiSelect-select.MuiSelect-select {
    font-size: 1em;
    font-weight: 300;
    padding: 10px 14px;

}

.alert-invoice {
    background-color: $background-final-price;
    padding: 0px 10%;
    color: #fff;
    font-size: 1em;
    margin: auto;
    text-align: justify;
}

.alert-invoice1 {
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
}

.alert-invoice-ticket {
    background-color: $background-blue-dark;
    padding: 1% 1%;
    color: #fff;
    font-size: 0.7em;
    text-align: justify;
}

.dataHead {
    background-color: $background-red;

}

.dataFont {
    color: $font-white;
    font-weight: bold;
    font-size: 1.3em;
}

.notSearch {
    background-color: #e51f04;
    color: white;
    margin-bottom: 0.5em !important;
    font-size: 0.7em;
}

.btnInvs {
    justify-content: center;
    text-align: center;
}