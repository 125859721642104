.state-machine {
    padding: 0 10%;
    font-family: 'Montserrat', sans-serif;
}

.tb-itm.completed div.tb-indctr {
    background-color: #F7F7F7;
}

.tb-itm div.tb-indctr {
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
    float: left;
    height: 3.3em;
    width: 100%;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: relative;

    h4 {
        font-weight: 500;
    }

}

.tb-itm.block div.tb-indctr {
    background-color: #416072;
    color: white;
}

#step-PAYMENT {
    border-top-right-radius: 1.6em;
    border-bottom-right-radius: 1.6em;
}

#step-SEARCH {
    border-top-left-radius: 1.6em;
    border-bottom-left-radius: 1.6em;
}

.tb-lines {
    z-index: 9;
    color: #B4B4B4;
    position: absolute;

    ::after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-right: 28px solid #ffff;
        top: -16px;
        left: 8.4vw;
        transform: rotate(180deg);
        content: '';
    }
}

.tb-lines.completed {
    ::after {
        border-right: 24px solid #F7F7F7;
    }
}

.tb-lines.selected {
    ::after {
        border-right: 24px solid #EBEBEB;
    }
}

.tb-lines.block {
    ::after {
        border-right: 24px solid #416072;
    }
}


.tb-line {
    height: 1.5em;
}

.tb-lin {
    position: absolute;
    z-index: 9;
    color: #B4B4B4;
    margin-left: 18.1vw;
}

.tb-line1,
.tb-line2 {
    border-right: solid 1px #B4B4B4;
    height: 1.7em;
}

.tb-line1 {
    transform: skewX(40deg);
}

.tb-line2 {
    transform: skewX(-40deg);
}



#lines-PAYMENT,
#line-PAYMENT {
    display: none;
}

.tb-itm.completed {
    cursor: pointer;
}

#booking-wizard-tab_FLIGHT {}

.tb-itm {
    width: 20%;
    float: left;
    margin: 0;
    border: 0;
    box-sizing: border-box;


}

.tb-iconStep {
    width: 8%;
    height: 50%;
    margin: 5px;
}





.bkng-tabs {
    float: left;
    width: 100%;
    margin-bottom: 0px;
    border: 1px solid #B4B4B4;
    border-radius: 1.5em;
    height: 3.5em;
}

.bkng-wzrd {
    width: 100%;
    float: left;
}

.tb-itm.selected div.tb-indctr i {
    display: block;
}

.tb-itm div.tb-indctr i {
    color: #EBEBEB;
    font-size: 17px;
    margin-top: 3px;
    text-align: center;
    width: 100%;
    display: none;
}

.tb-itm.selected div.tb-indctr {
    background-color: #EBEBEB;
}

.red-state {
    color: #EBEBEB;
    margin-top: 3px;
}

.h6-margin {
    margin: 0px;
}

// Modal styles
.dlg {
    padding: 2em 0;
}

.dlg div.vp {
    max-width: 800px;
    width: 100%;
    min-width: 320px;
}

.vp {
    display: table;
    margin: 0 auto;
}

.dlg h2 {
    float: left;
    margin: 0;
    width: 100%;
}

.dlg h2 i {
    color: #fdb400;
    float: left;
    font-size: 40px;
    padding-top: 3px;
    text-align: left;
    width: 50px;
}

.dlg h2 span {
    float: right;
    font-size: 34px;
    margin: 0;
    text-align: left;
    width: calc(100% - 50px);
    color: #444;
    font-family: 'Inter';
    font-weight: 300;
}

.dlg div.dcntnt {
    float: left;
    font-size: 20px;
    margin: 0;
    padding: 5px 0 20px 60px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
}

.dlg div.btns {
    display: inline-flex;

    #dialog_cancel_button{
        margin-left: 2em;
    }

    .dialog-option-button {
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        gap: 10px;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 0.9em;
    }
}



a {
    cursor: pointer;
    display: block;
    text-decoration: none;
}

.warning-icon {
    color: #fdb400;
    font-size: 40px;
    margin-right: 5px;
}

.step-modal-root {
    font-family: "Montserrat";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .MuiBackdrop-root {
        background-color: rgba($color: #fff, $alpha: 3/4%);
        justify-content: center;
        backdrop-filter: blur(8px);
    }
}


.modal-steps-2 {
    position: fixed;
    background-color: #fff;
    border-radius: 2em;
    width: 30%;
    min-height: 250px;
    padding: 2em 3em;
    box-shadow: 0px 0px 13.3px 0px #0000001F;
    cursor: default;
    overflow: auto;

    .modal-title {
        font-weight: 700;
        font-size: 2em;
        line-height: 26.82px;
    }

    .modal-description {
        font-weight: 400;
        font-size: 1em;
        line-height: 23.52px;
        padding: 1em 0;
        border-bottom: 1px solid #DFDFDF;

        strong {
            color: #E51F04;
        }

        span{
            line-height: 3.5em;
        }
    }
}

.icon-button {
    margin-right: 10px;
}

@media only screen and (max-width: 460px) {
    .h6-margin {
        display: none;
    }

    .tb-lines {
        ::after {
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            top: -13px;
            left: 9.2vw;
        }
    }

    .tb-lines.completed ::after {
        border-right: 22px solid #F7F7F7;
    }

    .tb-lin {
        margin-left: 24vw;
    }
}

@media only screen and (max-width:1400px){

    .bkng-tabs{
        margin-top: 1em;
    } 

    .tb-lines {
        ::after {
            border-top: 26px solid transparent;
            border-bottom: 26px solid transparent;
            top: -13px;
            left: 7.8vw;
        }
    }

    .tb-lines.completed ::after {
        border-right: 26px solid #F7F7F7;
    }

    .tb-lin {
        margin-left: 17.5vw;
    }
}


@media only screen and (max-width: 1024px) {
    .bkng-tabs{
        margin-top: 3em;
    } 

    .tb-iconStep {
        margin: 19px;
    }

    .tb-lines {
        ::after {
            border-top: 24px solid transparent;
            border-bottom: 24px solid transparent;
            top: -13px;
            left: 7.5vw;
        }
    }

    .tb-lines.completed ::after {
        border-right: 24px solid #F7F7F7;
    }

    .tb-lin {
        margin-left: 17vw;
    }

}

@media only screen and (min-width:461px )and (max-width: 960px) {
    .bkng-tabs{
        margin-top: 3em;
    }

}

@media only screen and (min-width:320px )and (max-width: 460px) {
    .h6-margin {
        display: none;
    }

    .tb-lines {
        ::after {
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
            border-right: 20px solid #ffff;
            top: -13px;
            left: 9.2vw;
        }
    }

    .tb-lines.completed ::after {
        border-right: 22px solid #F7F7F7;
    }

    .tb-lin {
        margin-left: 24vw;
    }

    .modal-steps-2{
        width: 94%;
        padding: 2em;
    } 
}

